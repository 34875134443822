import { ClientResponse } from '../interfaces/common';
import { PageResponse, QuestionsResponse } from '../interfaces/library';
import { get, post } from '../utils/api';

interface bookEvents {
	type: 'read_book' | 'question_answered';
	questionID?: string;
	answerID?: string;
}
const getBooks = async (courseID: string) => {
	const { data } = await get('/book', {
		headers: {
			'Kidint-Course-ID': courseID,
		},
	});
	return data.data;
};

const getPages = async (id: string) => {
	const { data } = await get<ClientResponse<PageResponse>>(`/book/${id}/pages`);
	return data.data;
};

const getQuestions = async (id: string, schoolId: string, courseId: string) => {
	const { data } = await get<ClientResponse<QuestionsResponse>>(`/book/${id}/questions`, {
		headers: {
			'kidint-school-id': schoolId,
			'kidint-course-id': courseId,
		},
	});
	return data.data;
};

const sendEvents = async (
	studentId: string,
	bookId: string,
	events: bookEvents | bookEvents[],
	courseId?: string,
	schoolId?: string,
) => {
	const { data } = await post<ClientResponse>(
		`/book/${bookId}/save_book_progress`,
		{
			progress: events,
		},
		{
			headers: {
				'kidint-current-student': studentId,
				'kidint-school-id': schoolId,
				'kidint-course-id': courseId,
			},
		},
	);
	return data.data;
};

export const BooksServices = {
	getBooks,
	getPages,
	getQuestions,
	sendEvents,
};
